import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { JotFormComponent } from './jot-form/jot-form.component';
import { ImportPoliciesComponent } from './import-policies/import-policies.component';
import { PaymentsTabComponent } from './payments-tab/payments-tab.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'insured-home', loadChildren: () => import('./insured-home/insured-home.module').then(m => m.InsuredHomeModule) },
  { path: 'insured-home-info', loadChildren: () => import('./insured-home-info/insured-home-info.module').then(m => m.InsuredHomeInfoModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'hometwo', loadChildren: () => import('./hometwo/hometwo.module').then(m => m.HometwoModule) },
  { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
  { path: 'homelanding', loadChildren: () => import('./homelanding/homelanding.module').then(m => m.HomelandingModule) },
  { path: 'thank-you', loadChildren: () => import('./generic-thankyou/generic-thankyou.module').then(m => m.GenericThankyouModule) },
  { path: 'thank-you-for-esign', loadChildren: () => import('./thank-you/thank-tou-module').then(m => m.ThankYouModule) },
  { path: 'resetpassword/:id', loadChildren: () => import('./resetpassword/resetpassword.module').then(m => m.ResetpasswordModule) },
  { path: 'faqs', loadChildren: () => import('./faqs/faqs.module').then(m => m.FaqsModule) },
  { path: 'ourstaff', loadChildren: () => import('./ourstaff/ourstaff.module').then(m => m.OurstaffModule) },
  { path: 'aboutus', loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutusModule) },
  { path: 'signup', loadChildren: () => import('./signupbroker/signupbroker.module').then(m => m.SignupbrokerModule) },

  { path: 'newquote', loadChildren: () => import('./newquote/newquote.module').then(m => m.NewquoteModule) },
  { path: 'quoteabout', loadChildren: () => import('./quoteabout/quoteabout.module').then(m => m.QuoteaboutModule) },
  { path: 'quoteyourbusiness', loadChildren: () => import('./quoteyourbusiness/quoteyourbusiness.module').then(m => m.QuoteyourbusinessModule) },
  { path: 'quoteclasscodedesc', loadChildren: () => import('./quoteclasscodedesc/quoteclasscodedesc.module').then(m => m.QuoteclasscodedescModule) },
  { path: 'quoteadditionalcoverage', loadChildren: () => import('./quoteadditionalcoverage/quoteadditionalcoverage.module').then(m => m.QuoteadditionalcoverageModule) },
  { path: 'totalpremiumdue', loadChildren: () => import('./totalpremiumdue/totalpremiumdue.module').then(m => m.TotalpremiumdueModule) },

  { path: 'quotes', loadChildren: () => import('./quotes/quotes.module').then(m => m.QuotesModule), canActivate: [AuthGuard] },
  { path: 'renewal-quotes', loadChildren: () => import('./quotes/quotes.module').then(m => m.QuotesModule), canActivate: [AuthGuard] },
  { path: 'expired-quotes', loadChildren: () => import('./quotes/quotes.module').then(m => m.QuotesModule), canActivate: [AuthGuard] },
  { path: 'broker-quotes', loadChildren: () => import('./broker-quotes/broker-quotes.module').then(m => m.BrokerQuotesModule), canActivate: [AuthGuard] },
  { path: 'wbroker-quotes', loadChildren: () => import('./wbroker-quotes/wbroker-quotes.module').then(m => m.WbrokerQuotesModule), canActivate: [AuthGuard] },

  { path: 'broker-expired-quotes', loadChildren: () => import('./broker-quotes/broker-quotes.module').then(m => m.BrokerQuotesModule), canActivate: [AuthGuard] },
  { path: 'broker-renewal-quotes', loadChildren: () => import('./broker-quotes/broker-quotes.module').then(m => m.BrokerQuotesModule), canActivate: [AuthGuard] },
  { path: 'broker-issued-quotes', loadChildren: () => import('./broker-quotes/broker-quotes.module').then(m => m.BrokerQuotesModule), canActivate: [AuthGuard] },
  { path: 'quote-summary/:id', loadChildren: () => import('./quote-summary/quote-summary.module').then(m => m.QuoteSummaryModule), canActivate: [AuthGuard] },
  { path: 'quote-policy/:id', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule), canActivate: [AuthGuard] },
  { path: 'quote-types', loadChildren: () => import('./quote-types/quote-types.module').then(m => m.QuoteTypesModule), canActivate: [AuthGuard] },
  { path: 'contractors-eco/:id', loadChildren: () => import('./contractors-eo/contractors-eo.module').then(m => m.ContractorsEoModule), canActivate: [AuthGuard] },
  { path: 'workers-compensation/:id', loadChildren: () => import('./workers-compensation/workers-compensation.module').then(m => m.WorkersCompensationModule), canActivate: [AuthGuard] },
  { path: 'quote-underwriting/:id', loadChildren: () => import('./quote-underwriting/quote-underwriting.module').then(m => m.QuoteUnderwritingModule), canActivate: [AuthGuard] },
  { path: 'insured-info/:id', loadChildren: () => import('./insured-info/insured-info.module').then(m => m.InsuredInfoModule), canActivate: [AuthGuard] },
  { path: 'quote-irpm/:id', loadChildren: () => import('./quote-irpm/quote-irpm.module').then(m => m.QuoteIrpmModule), canActivate: [AuthGuard] },
  { path: 'quote-history/:id', loadChildren: () => import('./quote-history/quote-history.module').then(m => m.QuoteHistoryModule), canActivate: [AuthGuard] },
  { path: 'request-endorsement/:id', loadChildren: () => import('./request-endorsement/request-endorsement.module').then(m => m.RequestEndorsementModule), canActivate: [AuthGuard] },
  { path: 'quote-claims/:id', loadChildren: () => import('./quote-claims/quote-claims.module').then(m => m.QuoteClaimsModule), canActivate: [AuthGuard] },
  
  { path: 'claims/:id', loadChildren: () => import('./claims/claims.module').then(m => m.ClaimsModule), canActivate: [AuthGuard] },
  { path: 'claims-client/:id', loadChildren: () => import('./claims/claims.module').then(m => m.ClaimsModule) },
  { path: 'audits/:id', loadChildren: () => import('./audits/audits.module').then(m => m.AuditsModule), canActivate: [AuthGuard] },
  { path: 'audits-client/:id', loadChildren: () => import('./audits/audits.module').then(m => m.AuditsModule) },
  { path: 'inspections/:id', loadChildren: () => import('./inspections/inspections.module').then(m => m.InspectionsModule), canActivate: [AuthGuard] },
  { path: 'inspections-client/:id', loadChildren: () => import('./inspections/inspections.module').then(m => m.InspectionsModule) },

  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard] },
  { path: 'quote-tab', loadChildren: () => import('./quote-tab/quote-tab.module').then(m => m.QuoteTabModule), canActivate: [AuthGuard] },
  { path: 'quote-tab-search', loadChildren: () => import('./quote-tab-search/quote-tab-search.module').then(m => m.QuoteTabSearchModule), canActivate: [AuthGuard] },

  { path: 'brokers', loadChildren: () => import('./brokers/brokers.module').then(m => m.BrokersModule), canActivate: [AuthGuard] },
  { path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule), canActivate: [AuthGuard] },
  { path: 'broker', loadChildren: () => import('./addbroker/addbroker.module').then(m => m.AddbrokerModule), canActivate: [AuthGuard] },
  { path: 'admins', loadChildren: () => import('./admins/admins.module').then(m => m.AdminsModule), canActivate: [AuthGuard] },
  { path: 'old-list/:id', loadChildren: () => import('./claims-list/claims-list.module').then(m => m.ClaimsListModule), canActivate: [AuthGuard] },
  { path: 'mail-templates', loadChildren: () => import('./mail-templates/mail-templates.module').then(m => m.MailTemplatesModule), canActivate: [AuthGuard] },
  { path: 'mail-signatures', loadChildren: () => import('./mail-signatures/mail-signatures.module').then(m => m.MailSignaturesModule), canActivate: [AuthGuard] },

  { path: 'excess-liability', loadChildren: () => import('./excess-liability/excess-liability.module').then(m => m.ExcessLiabilityModule), canActivate: [AuthGuard] },

  { path: 'workers-compensation-shield', loadChildren: () => import('./workers-compensation/workers-compensation.module').then(m => m.WorkersCompensationModule) },
  { path: 'thank-you-wc', loadChildren: () => import('./thank-you-wc/thank-you-wc-module').then(m => m.ThankYouWcModule) },
  { path: 'imu-form-submit', loadChildren: () => import('./imu-form-submit/imu-form-submit-module').then(m => m.ImuFormSubmitModule) },
  { path: 'installation-floater', loadChildren: () => import('./installation-floater/installation-floater.module').then(m => m.InstallationFloaterModule), canActivate: [AuthGuard] },
  { path: 'contractors-equipment/:id', loadChildren: () => import('./contractors-equipment/contractors-equipment.module').then(m => m.ContractorsEquipmentModule), canActivate: [AuthGuard] },
  { path: 'contractors-equipment-form/:id', loadChildren: () => import('./contractors-equipment/contractors-equipment.module').then(m => m.ContractorsEquipmentModule) },
  { path: 'all-quote-documents/:id', loadChildren: () => import('./all-quote-documents/all-quote-documents.module').then(m => m.AllQuoteDocumentsModule) },
  { path: 'tutorials', loadChildren: () => import('./tutorials/tutorials.module').then(m => m.TutorialsModule), canActivate: [AuthGuard] },
  { path: 'builders-risk', loadChildren: () => import('./builders-risk/builders-risk.module').then(m => m.BuildersRiskModule), canActivate: [AuthGuard] },
  { path: 'builders-risk/:id', loadChildren: () => import('./builders-risk/builders-risk.module').then(m => m.BuildersRiskModule), canActivate: [AuthGuard] },
  { path: 'eo-quote-summary/:id', loadChildren: () => import('./eo-quote-summary/eo-quote-summary.module').then(m => m.EoQuoteSummaryModule), canActivate: [AuthGuard] },
  { path: 'builders-risk-quote-summary/:id', loadChildren: () => import('./builders-risk-quote-summary/builders-risk-quote-summary.module').then(m => m.BuildersRiskQuoteSummaryModule), canActivate: [AuthGuard] },
  { path: 'import-policies', component: ImportPoliciesComponent ,canActivate: [AuthGuard] },
  { path: 'erma-payroll-rater/:id', loadChildren: () => import('./erma-payroll-rater/erma-payroll-rater.module').then(m => m.ErmaPayrollRaterModule), canActivate: [AuthGuard] },
  { path: 'erma-quote-summary/:id' , loadChildren:() => import('./erma-quote-summary/erma-quote-summary.module').then(m =>m.ErmaQuoteSummaryModule), canActivate: [AuthGuard] },
  { path: 'record-payments/:id', loadChildren: () => import('./record-payments/record-payments.module').then(m => m.RecordPaymentsModule), canActivate: [AuthGuard] },
  { path: 'non-contractors-gl/:id', loadChildren: () => import('./lro/lro.module').then(m => m.LroModule), canActivate: [AuthGuard] },
  { path: 'non-contractors-gl-underwriting/:id', loadChildren: () => import('./lro-underwriting/lro-underwriting.module').then(m => m.LroUnderwritingModule), canActivate: [AuthGuard] },
  { path: 'non-contractors-gl-quote-summary/:id', loadChildren: () => import('./lro-quote-summary/lro-quote-summary.module').then(m => m.LroQuoteSummaryModule), canActivate: [AuthGuard] },
  { path: 'jot-form', component: JotFormComponent, canActivate: [AuthGuard] },
  { path: 'payments-tab', component:PaymentsTabComponent, canActivate:[AuthGuard]},
  { path: 'release-notes', loadChildren: () => import('./release-notes/release-notes.module').then(m => m.ReleaseNotesModule), canActivate: [AuthGuard] },
  { path: 'add-edit-release-notes/:id', loadChildren: () => import('./add-edit-release-notes/add-edit-release-notes.module').then(m => m.AddEditReleaseNotesModule), canActivate: [AuthGuard] },
  { path: 'master-settings', loadChildren: () => import('./master-settings/master-settings.module').then(m => m.MasterSettingsModule), canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
